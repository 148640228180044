import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import BritishFlag from '../svg/BritishFlag'
import ChinaFlag from '../svg/ChinaFlag'
import Chevron from '../svg/Chevron'
import I18nContext from '../lib/i18n-context'
import replaceFontFamilies from '../lib/replace-font-families'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LocaleSwitcher = ({ navColour }) => {
    const i18nContext = React.useContext(I18nContext)

    const { currentLocale, setCurrentLocale } = i18nContext

    const textColour = navColour === 'white' ? 'sm:text-darkblue' : 'text-darkblue sm:text-white'

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className={`${textColour} inline-flex w-full items-center justify-center space-x-1 rounded-full border duration-300 ease-in-out transition border-gray-300 bg-white/10 pr-4 text-sm font-medium shadow-sm hover:bg-white/50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100`}
                >
                    <div className="flex items-center relative pl-10 h-full py-1 pr-1">
                        {currentLocale === 'en' && (
                            <>
                                <BritishFlag />
                                <span>EN</span>
                            </>
                        )}
                        {currentLocale === 'cn' && (
                            <>
                                <ChinaFlag />
                                <span>中国</span>
                            </>
                        )}
                    </div>
                    <Chevron />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-18 origin-top-right bg-none rounded-md shadow-lg focus:outline-none">
                    <div className="space-y-2">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#en"
                                    onClick={() => {
                                        setCurrentLocale('en')
                                        replaceFontFamilies('en')
                                        window.location.reload()
                                    }}
                                    className={classNames(
                                        active ? 'bg-white/50' : 'bg-white/50',
                                        'pl-10 pr-[18px] py-1 text-sm flex border border-white bg-white/[0.35] w-full text-darkblue relative rounded-full hover:bg-white/75 transition duration-300 ease-in-out'
                                    )}
                                >
                                    <BritishFlag />
                                    <span>EN</span>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#cn"
                                    onClick={() => {
                                        setCurrentLocale('cn')
                                        replaceFontFamilies('cn')
                                        window.location.reload()
                                    }}
                                    className={classNames(
                                        active ? 'bg-white/50' : 'bg-white/50',
                                        'pl-10 pr-[18px] py-1 text-sm flex border border-white bg-white/[0.35] hover:bg-white/75 w-full text-darkblue relative rounded-full transition duration-300 ease-in-out'
                                    )}
                                >
                                    <ChinaFlag />
                                    <span>
                                        {currentLocale === 'en' && 'CN'}
                                        {currentLocale === 'cn' && '中国'}
                                    </span>
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default LocaleSwitcher
