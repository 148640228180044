import React from 'react'

const BritishFlag = () => (
    <svg
        version="1.2"
        baseProfile="tiny"
        id="uk-flag"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 35.55 35.55"
        overflow="visible"
        className="w-7 border-2 border-white rounded-full absolute left-0 inset-y-0"
    >
        <path
            fillRule="evenodd"
            fill="#F0F0F0"
            d="M17.78-0.26c-9.96,0-18.04,8.08-18.04,18.04c0,9.96,8.08,18.04,18.04,18.04
 s18.04-8.08,18.04-18.04C35.82,7.81,27.74-0.26,17.78-0.26L17.78-0.26z"
        />
        <path
            fillRule="evenodd"
            fill="#32667B"
            d="M14.68,0c-3.21,0.55-6.13,1.96-8.52,3.97l8.52,8.52V0L14.68,0z M20.87,12.5l8.52-8.52
 C27,1.96,24.08,0.56,20.87,0V12.5L20.87,12.5z M3.97,6.16C1.96,8.55,0.56,11.47,0,14.68h12.5L3.97,6.16L3.97,6.16z M0,20.87
 c0.56,3.21,1.96,6.13,3.97,8.52l8.52-8.52H0L0,20.87z M6.16,31.58c2.39,2.01,5.31,3.42,8.52,3.97v-12.5L6.16,31.58L6.16,31.58z
  M20.87,35.55c3.21-0.56,6.13-1.96,8.52-3.97l-8.52-8.52V35.55L20.87,35.55z M31.58,29.39c2.01-2.39,3.42-5.31,3.97-8.52h-12.5
 L31.58,29.39L31.58,29.39z M35.55,14.68c-0.56-3.21-1.96-6.13-3.97-8.52l-8.52,8.52H35.55L35.55,14.68z"
        />
        <path
            fillRule="evenodd"
            fill="#B2092D"
            d="M5.39,4.66C5.14,4.9,4.9,5.14,4.66,5.39l9.29,9.29h0.73v-0.73L5.39,4.66L5.39,4.66z
  M13.96,20.87l-9.29,9.29c0.24,0.25,0.48,0.49,0.73,0.73l9.29-9.29v-0.73H13.96L13.96,20.87z M20.87,21.6l9.29,9.29
 c0.25-0.24,0.49-0.48,0.73-0.73l-9.29-9.29h-0.73V21.6L20.87,21.6z M21.6,14.68l9.29-9.29c-0.24-0.25-0.48-0.49-0.73-0.73
 l-9.29,9.29v0.73H21.6L21.6,14.68z"
        />
        <path
            fillRule="evenodd"
            fill="#B2092D"
            d="M17.78-0.26c-0.7,0-1.38,0.04-2.06,0.12v15.86H-0.15c-0.08,0.68-0.12,1.36-0.12,2.06
 c0,0.7,0.04,1.38,0.12,2.06h15.86V35.7c0.68,0.08,1.36,0.12,2.06,0.12c0.7,0,1.38-0.04,2.06-0.12V19.84H35.7
 c0.08-0.68,0.12-1.36,0.12-2.06c0-0.7-0.04-1.39-0.12-2.06H19.84V-0.15C19.16-0.22,18.47-0.26,17.78-0.26L17.78-0.26z"
        />
    </svg>
)

export default BritishFlag
