import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import { Link } from "gatsby";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import LocaleSwitcher from "../components/LocaleSwitcher";
import I18nContext from "../lib/i18n-context";

const NavBar = (props) => {
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
    hideHeader: true,
  });

  const menuBtn = useRef(null);
  const linkedinBtn = useRef(null);

  const openSideMenu = () => {
    setState({ isPaneOpen: true });
    menuBtn.current.classList = `hidden border-t-2 border-b-2 py-1 md:text-lg lg:text-xl cursor-pointer font-light ${props.class}`;
    linkedinBtn.current.classList = "hidden";
  };

  const closeSideMenu = () => {
    setState({ isPaneOpen: false });
    menuBtn.current.classList = `font-arima block border-t-2 border-b-2 py-1 md:text-lg lg:text-xl cursor-pointer font-light ${props.class}`;
    linkedinBtn.current.classList = "block";
  };

  // i18n
  const i18nContext = React.useContext(I18nContext);
  const { locale } = i18nContext;

  const data = locale.appHeader;

  useEffect(() => {
    $(document).ready(() => {
      $(window).scroll(() => {
        if (state.isPaneOpen === true) {
          $("#menu-btn").addClass("hidden");
        }
        if (state.isPaneOpen === false && window.scrollY > 20) {
          $(".blue-navbar").addClass("bg-darkblue");
          $(".white-navbar").addClass("bg-white");
          $("#menu-btn").removeClass("hidden");
        } else if (state.isPaneOpen === true) {
          $(".blue-navbar").removeClass("bg-darkblue");
          $(".white-navbar").removeClass("bg-white");
        } else if (state.isPaneOpen === true) {
          $("#menu-btn").addClass("hidden");
        } else {
          $(".blue-navbar").removeClass("bg-darkblue");
          $(".white-navbar").removeClass("bg-white");
          $("#menu-btn").removeClass("hidden");
        }
      });
    });
  });

  return (
    <>
      <nav
        className={`fixed top-0 flex justify-between items-center w-full px-10 lg:px-20 py-6 z-10 text-white ${props.navClass}`}
      >
        <div className="w-24 md:w-32 xl:w-36">
          <Link to="/">
            {" "}
            <img src={props.logo} alt="company logo" />{" "}
          </Link>
        </div>

        <div className="flex items-center space-x-12">
          <div className={`hidden sm:block ${state.isPaneOpen ? 'sm:hidden' : ''}`}>
            <LocaleSwitcher navColour={props.navClass === 'white-navbar' ? 'white' : 'blue'} />
          </div>
          <div ref={linkedinBtn}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/qiva-global/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill={props.svgColour ? props.svgColour : "#1e3d49"}
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
            </a>
          </div>
          <div
            onClick={() => openSideMenu()}
            aria-hidden="true"
            id="menu-btn"
            className={`font-arima border-t-2 border-b-2 py-1 md:text-lg lg:text-xl cursor-pointer font-light ${props.class}`}
            ref={menuBtn}
          >
            <div className="-mb-1">{data.buttonTitle}</div>
          </div>
        </div>
      </nav>
      <SlidingPane
        width="60vw"
        hideHeader
        isOpen={state.isPaneOpen}
        onRequestClose={() => {
          setState({ isPaneOpen: null });
        }}
        className="navbar text-right py-10 lg:px-10 md:pl-20 xl:pl-24 shadow-l h-full z-50"
      >
        <button onClick={() => closeSideMenu()} className="pb-10 pt-6 sm:pt-10">
          <svg
            className="w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
        <ul className="font-light font-arima sm:text-lg lg:text-xl text-lightblue overflow-auto">
          {data.menu.map((item, index) => (
            <li key={index} className="py-4 border-t border-darkblue lg:py-6 hover:text-black pr-2">
              <Link to={item.link}> {item.title} </Link>
            </li>
          ))}
          <li className="py-4 border-t border-b border-darkblue lg:py-6 hover:text-black pr-2">
            <Link
              to="/#contact-us"
              onClick={() => {
                setState({ isPaneOpen: false });
              }}
            >
              {" "}
              {data.contactUs}
            </Link>
          </li>
        </ul>
        <div className="sm:hidden pt-4">
          <LocaleSwitcher />
        </div>
      </SlidingPane>
    </>
  );
};
export default NavBar;
