import React from 'react'

const ChinaFlag = () => (
    <svg
        version="1.2"
        baseProfile="tiny"
        id="china-flag"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 36.08 36.08"
        overflow="visible"
        className="w-7 border-2 border-white rounded-full absolute left-0 inset-y-0"
    >
        <path
            fillRule="evenodd"
            fill="#B2092D"
            d="M18.04,0C8.08,0,0,8.08,0,18.04C0,28,8.08,36.08,18.04,36.08S36.08,28,36.08,18.04
	C36.08,8.08,28,0,18.04,0L18.04,0z"
        />
        <polygon
            fillRule="evenodd"
            fill="#FFDA44"
            points="11.92,9.85 13.88,14.5 18.91,14.92 15.09,18.22 16.24,23.14 11.92,20.52 
	7.61,23.14 8.76,18.22 4.94,14.92 9.97,14.5 11.92,9.85 "
        />
        <polygon
            fillRule="evenodd"
            fill="#FFDA44"
            points="24.3,4.04 25.18,6.13 27.44,6.32 25.72,7.8 26.24,10 24.3,8.83 22.36,10 
	22.88,7.8 21.17,6.32 23.42,6.13 24.3,4.04 "
        />
        <polygon
            fillRule="evenodd"
            fill="#FFDA44"
            points="29.77,9.6 30.65,11.69 32.91,11.88 31.19,13.36 31.71,15.56 29.77,14.39 
	27.84,15.56 28.35,13.36 26.64,11.88 28.89,11.69 29.77,9.6 "
        />
        <polygon
            fillRule="evenodd"
            fill="#FFDA44"
            points="29.77,18.3 30.65,20.39 32.91,20.57 31.19,22.05 31.71,24.26 29.77,23.09 
	27.84,24.26 28.35,22.05 26.64,20.57 28.89,20.39 29.77,18.3 "
        />
        <polygon
            fillRule="evenodd"
            fill="#FFDA44"
            points="24.3,25.22 25.18,27.31 27.44,27.5 25.72,28.98 26.24,31.19 24.3,30.01 
	22.36,31.19 22.88,28.98 21.17,27.5 23.42,27.31 24.3,25.22 "
        />
    </svg>
)

export default ChinaFlag
