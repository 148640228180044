import React from "react";
import { Link } from "gatsby";
import I18nContext from "../lib/i18n-context";

const Footer = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.footer;

  return (
    <footer className="w-full bg-darkblue p-8">
      <div className="flex justify-between items-center text-white md:px-6 xl:px-12">
        <div>
          <img
            className=" w-24"
            src="/assets/main-logo.svg"
            alt="Qiva brand logo"
          />
        </div>
        <div className="flex items-center space-x-4 sm:space-x-6">
          <div>
            <Link
              to="/privacy-notice"
              className="font-poppins hover:underline text-xs font-light"
            >
              {data.privacy}
            </Link>
          </div>
          <div>
            <Link
              to="/#contact-us"
              className="font-poppins text-xs hover:underline font-light"
            >
              {data.contact}
            </Link>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/qiva-global/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="white"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
